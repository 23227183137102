.brand {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}

.name {
    font-family: "Circular Pro",Arial,sans-serif;
    font-weight: 400;
    font-size: 1.3em;
}
.tier {
    font-family: "Circular Pro",Arial,sans-serif;
    font-weight: 400;
    font-size: 1.3em;
}

.packageSize {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}

.romanceCopyString {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}

.promotionImage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newPromotionImage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headlineString {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}

.salePrice {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    margin-top: 0.4em;
    margin-bottom: 0.6em;
}

.promoImage {
    margin-top: 0.8em;
}

.primePrice {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    margin-top: 0.4em;
    margin-bottom: 0.6em;
}

.primePriceColour {
    color:  #00a8e1;
}

.regPrice {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}

.valid {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
    font-size: .875em;
    color: #565553;
}

.inStoreFlg {
    font-family: "Circular Pro",Verdana,sans-serif;
    font-weight: 400;
}
